var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{attrs:{"items":_vm.sponsors,"tableOptions":_vm.tableOptions,"modifyItem":_vm.modifySponsor,"options":_vm.options},on:{"update:items":function($event){_vm.sponsors=$event},"update:tableOptions":function($event){_vm.tableOptions=$event},"update:table-options":function($event){_vm.tableOptions=$event},"update:modifyItem":function($event){_vm.modifySponsor=$event},"update:modify-item":function($event){_vm.modifySponsor=$event}},scopedSlots:_vm._u([{key:"deleteConfirmationTitle",fn:function(){return [_vm._v(" Sponsor Delete Confirmation: "+_vm._s(_vm.modifySponsor.name)+" ")]},proxy:true},{key:"deleteConfirmationText",fn:function(){return [_vm._v(" Are you sure you want to delete this sponsor? ")]},proxy:true},{key:"addBtn",fn:function(){return [_vm._v("mdi-plus")]},proxy:true},{key:"addTooltip",fn:function(){return [_vm._v("Add New Sponsor")]},proxy:true},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.logoLight",fn:function(ref){
var item = ref.item;
return [_c('v-img',{key:item.md5,staticClass:"my-2",attrs:{"src":(_vm.serverURL + "assets/img/thumb/" + (item.logoLight.filename) + ".webp?" + (item.logoLight.md5)),"max-height":"120","max-width":"120"}})]}},{key:"item.logoDark",fn:function(ref){
var item = ref.item;
return [_c('v-img',{key:item.md5,staticClass:"my-2",attrs:{"src":(_vm.serverURL + "assets/img/thumb/" + (item.logoDark.filename) + ".webp?" + (item.logoDark.md5)),"max-height":"120","max-width":"120"}})]}},{key:"item.creationTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.creationTimestamp).format('MMMM D, YYYY h:mm A'))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }