



















import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
import { API } from '@/types';
import { removeDBTimestamps } from '@/util';
import ModifyDialog from '@/components/Admin/ModifyDialog.vue';
import TextInput from '@/components/Admin/TextInput.vue';
import ImageSelect from '@/components/Admin/ImageSelect.vue';
import { apiModify } from '@/api';

@Component({
  components: {
    ModifyDialog,
    TextInput,
    ImageSelect,
  },
})
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @Prop(Object) readonly modifyItem!: API.Sponsors.GET | undefined;
  sponsor: API.Sponsors.POST = {};
  sponsorId: number | null = null;
  err: boolean | string = false;

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (val && this.modifyItem) {
      this.sponsor = removeDBTimestamps(this.modifyItem);
      this.sponsorId = this.modifyItem.id;
    }
  }

  cancel(): void {
    this.sponsor = {};
    this.sponsorId = null;
    this.err = false;
  }

  async save(): Promise<void> {
    try {
      const { data } = await apiModify('sponsors', this.sponsorId, this.sponsor);
      this.sponsor = {};
      this.sponsorId = null;
      this.err = false;
      this.dialogProp = false;
      this.$emit('post', data);
    } catch (err) {
      const { data } = err.response;
      if (data && data.error && !Array.isArray(data.error)) {
        this.err = data.error;
      } else {
        this.err = 'Some server error occured';
      }
    }
  }
}
