var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"650"},on:{"click:outside":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.label,"value":_vm.textFieldValue,"prepend-icon":"mdi-file-image","readonly":"","filled":"","hide-details":"","clearable":_vm.clearable},on:{"click:clear":function($event){_vm.selectedImage = null; _vm.$emit('input', null)}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Select Image "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){_vm.dialog = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{staticClass:"pb-2"},[(_vm.dialog)?_c('data-table',{attrs:{"items":_vm.images,"tableOptions":_vm.tableOptions,"modifyItem":_vm.selectedImage,"options":_vm.options,"hide-default-header":"","itemsPerPageOptions":[3, 6, 9]},on:{"update:items":function($event){_vm.images=$event},"update:tableOptions":function($event){_vm.tableOptions=$event},"update:table-options":function($event){_vm.tableOptions=$event},"update:modifyItem":function($event){_vm.selectedImage=$event},"update:modify-item":function($event){_vm.selectedImage=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('v-container',[_c('v-row',{attrs:{"dense":""}},_vm._l((items),function(image){return _c('v-col',{key:image.id,staticClass:"d-flex",attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{style:({ width: '100%', opacity: hover ? 1 : 0.9 }),attrs:{"elevation":hover ? 10 : 3},on:{"click":function($event){_vm.selectedImage = image; _vm.save()}}},[_c('v-img',{staticClass:"grey darken-4",attrs:{"contain":"","src":(_vm.serverURL + "assets/img/thumb/" + (image.filename) + ".webp?" + (image.md5)),"aspect-ratio":"1.3"}}),_c('v-card-title',{staticClass:"pa-3 text-subtitle-2 text-no-wrap text-truncate",style:({ display: 'block' })},[_vm._v(" "+_vm._s(image.originalFilename)+" ")])],1)]}}],null,true)})],1)}),1)],1)]}}],null,false,2753225222)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }