







































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/Admin/DataTable.vue';
import { API } from '@/types';
import { serverURL } from '@/util';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  @Prop(Number) readonly value!: number | null | undefined;
  @Prop(Object) readonly embed!: API.Images.GET | null | undefined;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop(Boolean) readonly clearable!: boolean;
  images: API.Images.GET[] = []; // Only stores the current page of images!
  tableOptions = { page: 1, itemsPerPage: 9 };
  selectedImage: API.Images.GET | null = null;
  options = {
    apiName: 'images',
    socketName: 'imageModified',
    idStr: 'id',
    headers: [],
    hideActions: true,
    hideAdd: true,
  };
  dialog = false;
  serverURL = serverURL();

  @Watch('value')
  onValueChange(val?: number | null): void {
    if (typeof val !== 'number') {
      this.selectedImage = null;
      this.tableOptions = { page: 1, itemsPerPage: 9 };
    }
  }

  @Watch('dialog')
  onDialogChange(val: boolean): void {
    if (!val) {
      this.images.length = 0;
    }
  }

  save(): void {
    if (this.selectedImage) {
      this.$emit('input', this.selectedImage.id);
      this.dialog = false;
    }
  }

  get textFieldValue(): string | undefined {
    if (!this.value) return undefined;
    return this.selectedImage?.originalFilename
      ?? this.embed?.originalFilename
      ?? `Name Not Found (ID: ${this.value})`;
  }
}
