















































import { Vue, Component } from 'vue-property-decorator';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import Modify from './Modify.vue';
import dayjs from 'dayjs';
import { serverURL } from '@/util';

@Component({
  components: {
    DataTable,
  },
})
export default class extends Vue {
  sponsors: API.Sponsors.GET[] = []; // Only stores the current page of sponsors!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifySponsor: API.Sponsors.GET | null = null; // Sponsor to be modified/deleted.
  options = {
    apiName: 'sponsors',
    socketName: 'sponsorModified',
    idStr: 'id',
    embeds: [
      { name: 'logoLight', socketName: 'imageModified', idStr: 'logoLightId' },
      { name: 'logoDark', socketName: 'imageModified', idStr: 'logoDarkId' },
    ],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Website URL', value: 'url' },
      { text: 'Logo for Light Backgrounds', value: 'logoLight' },
      { text: 'Logo for Dark Backgrounds', value: 'logoDark' },
      { text: 'Created', value: 'creationTimestamp' },
    ],
    modifyComponent: Modify,
  };
  serverURL = serverURL();
  dayjs = dayjs;
}
